import { getTimezoneId } from '../dateHelper';
import localStorageService from '../localStorageService';

export const getCommunityDataForAnalytics = (community) => {
  return {
    communityId: community?._id,
    communityCode: community?.code,
    communityTitle: community?.title
  };
};

export const getEventDataForAnalytics = (event) => {
  const isPaidEvent = event?.access === 'paid';
  return {
    eventId: event?._id,
    eventAmountCents: isPaidEvent ? event?.amount || 0 : 0,
    eventCurrency: isPaidEvent ? event?.currency || 'USD' : 'USD'
  };
};

const getLoggedInUserEmail = () => {
  try {
    return JSON.parse(localStorageService.userData).email;
  } catch (e) {
    return '';
  }
};

const getLoggedInUserId = () => {
  try {
    return JSON.parse(localStorageService.userData)._id;
  } catch (e) {
    return '';
  }
};

export const trackGeneralEvent = (actionId, payload = {}) => {
  const params = {
    actionId: actionId,
    email: getLoggedInUserEmail(),
    communityId: payload?.communityId,
    communityCode: payload?.communityCode,
    communityTitle: payload?.communityTitle,
    eventId: payload?.eventId,
    eventAmountCents: payload?.eventAmountCents,
    eventCurrency: payload?.eventCurrency,
    postId: payload?.postId,
    folderId: payload?.folderId,
    platform: payload?.platform,
    paymentMethod: payload?.paymentMethod,
    timestamp: new Date().getTime(),
    timezone: getTimezoneId(),
    userId: getLoggedInUserId(),
    ...payload
  };

  window?.dataLayer?.push({
    event: 'general_event',
    params
  });
};
