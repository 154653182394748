import { STATIC_ASSET_BASE_URL } from '@/utility/constants';
import { t } from '@/utility/localization';
import Player from '@/components/common/LottiePlayer';

export const EVENT_TYPE = {
  UPCOMING: 'upcoming',
  PAST: 'past'
};

export const [TAB_UPCOMING_EVENTS, TAB_PAST_EVENTS] = ['upcoming', 'past'];

export const getTabs = () => [
  {
    id: TAB_UPCOMING_EVENTS,
    label: t('upcoming'),
    testId: 'upcoming-events'
  },
  { id: TAB_PAST_EVENTS, label: t('past'), testId: 'past-events' }
];

export const [EVENT_STATUS_ACTIVE, EVENT_STATUS_CANCELLED] = [
  'Active',
  'Cancelled'
];

export const [EVENT_TYPE_LIVE, EVENT_TYPE_IN_PERSON] = [
  'live',
  'inPerson'
];

export const [EVENT_ACCESS_FREE, EVENT_ACCESS_PAID] = ['free', 'paid'];

export const defaultHostProfileImage = `${STATIC_ASSET_BASE_URL}/nasIO/portal/png/placeholder-guest-profile-picture.png`;

export const getDiscountAnnouncementModalProps = () => ({
  title: t('create-discount-for-paid-events'),
  description: t(
    'click-on-the-menu-to-create-a-new-discount-and-get-more-sales'
  ),
  imageProps: {
    alt: 'event-page',
    mobileImgProps: {
      src: `${STATIC_ASSET_BASE_URL}/nasIO/portal/discount/events.png`,
      width: 500,
      height: 250
    }
  }
});

export const getEditEventMessageCtaDetails = () => [
  {
    title: t('MEMBER_COMMUNITY_EVENT_REMINDER_24H'),
    description: t('sent-to-event-attendees-1-day-before-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_REMINDER_24H'
  },
  {
    title: t('MEMBER_COMMUNITY_EVENT_REMINDER_1H'),
    description: t('sent-to-event-attendees-1-hour-before-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_REMINDER_1H'
  }
];

export const getEventRegistrationMessageCtaDetails = () => [
  {
    title: t('MEMBER_COMMUNITY_EVENT_RSVP'),
    description: t('sent-to-event-attendees-who-are-going-for-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_RSVP'
  }
];

export const getEventRegistrationApprovalMessageCtaDetails = () => [
  {
    title: t('MEMBER_COMMUNITY_EVENT_PENDING_APPROVAL'),
    description: t('sent-to-people-who-apply-to-join-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_PENDING_APPROVAL'
  },
  {
    title: t('MEMBER_COMMUNITY_EVENT_APPROVED'),
    description: t('sent-to-people-who-are-approved-for-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_APPROVED'
  },
  {
    title: t('MEMBER_COMMUNITY_EVENT_REJECTED'),
    description: t('sent-to-people-who-are-rejected-for-the-event'),
    mailType: 'MEMBER_COMMUNITY_EVENT_REJECTED'
  }
];

export const getEventMailVariables = () => {
  return [
    { variable: '{name}', text: t('member-name-0') },
    { variable: '{event_name}', text: t('event-name') },
    { variable: '{community_name}', text: t('community-name') }
  ];
};

export default () => {
  const changePricingOptions = [
    {
      value: EVENT_ACCESS_PAID,
      label: t('paid'),
      testId: 'paid-event'
    },
    {
      value: EVENT_ACCESS_FREE,
      label: t('free'),
      testId: 'free-event'
    }
  ];

  const desktopAttendeesDataField = [
    {
      fieldName: 'name',
      secondaryFieldName: 'profileImage',
      thirdFieldName: 'ticketReferences',
      label: t('name'),
      isDisabledSort: true,
      fieldDisplayType: 'nameAvatarBadgeCombination'
    },
    {
      fieldName: 'email',
      label: t('email'),
      isDisabledSort: true,
      fieldDisplayType: 'email'
    },
    {
      fieldName: 'createdAt',
      label: t('registration-date'),
      fieldDisplayType: 'date',
      isDisabledSort: false
    },
    {
      fieldName: 'status',
      label: t('status'),
      fieldDisplayType: 'custom',
      isDisabledSort: false
    }
  ];

  const mobileAttendeesDataField = [
    {
      fieldName: 'name',
      secondaryFieldName: 'profileImage',
      thirdFieldName: 'email',
      fourthFieldName: 'ticketReferences',
      fifthFieldName: 'createdAt',
      label: '',
      isDisabledSort: true,
      fieldDisplayType: 'nameAvatarEmailBadgeDateCombination'
    },
    {
      fieldName: 'status',
      label: '',
      fieldDisplayType: 'statusPill',
      isDisabledSort: true
    }
  ];

  const [TAB_ATTENDEES, TAB_REGISTRATION, TAB_EMAILS] = [
    'attendees',
    'registration',
    'emails'
  ];

  const eventManagementTabs = [
    { id: TAB_ATTENDEES, label: t('attendees') },
    { id: TAB_REGISTRATION, label: t('registration') },
    { id: TAB_EMAILS, label: t('emails') }
  ];

  const [
    STATUS_HAPPENING_NOW,
    STATUS_EVENT_HAS_ENDED,
    STATUS_STARTING_SOON
  ] = ['happening-now', 'event-has-ended', 'starting-soon'];

  const eventStatusIcon = {
    [t('happening-now')]: {
      icon: (
        <Player
          className="h-20 w-20 mix-blend-screen"
          src="/animation/happening-now-lottie.json"
          loop
          autoplay
        />
      )
    },
    [t('event-has-ended')]: {
      icon: (
        <div className="h-[11px] w-[11px] rounded-full bg-npl-neutral-light-solid-9 p-2" />
      )
    }
  };

  return {
    changePricingOptions,
    desktopAttendeesDataField,
    mobileAttendeesDataField,
    eventManagementTabs,
    STATUS_HAPPENING_NOW,
    STATUS_EVENT_HAS_ENDED,
    STATUS_STARTING_SOON,
    eventStatusIcon,
    TAB_ATTENDEES,
    TAB_REGISTRATION,
    TAB_EMAILS
  };
};
