import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';

import useQueryParams from '@/hooks/useQueryParams';

const JoinWhatsappGroupCard = dynamic(
  () => import('./JoinWhatsappGroupCard')
);

const JoinWhatsappChatCard = ({
  isWhatsappExperienceCommunity,
  waGroupChatLink,
  isCommunityMember
}) => {
  const [showJoinWAGroupCard, setShowJoinWAGroupCard] = useState(false);
  const { currentRouterQuery } = useQueryParams();

  const hideJoinWAGroupCard = () => {
    setShowJoinWAGroupCard(false);
  };

  const showCard =
    showJoinWAGroupCard &&
    isWhatsappExperienceCommunity &&
    waGroupChatLink;

  useEffect(() => {
    if (currentRouterQuery?.showSignupSuccessPrompt) {
      setShowJoinWAGroupCard(true);
    }
  }, [currentRouterQuery?.showSignupSuccessPrompt]);

  return showCard ? (
    <div className="mx-16">
      <JoinWhatsappGroupCard
        waGroupChatLink={waGroupChatLink}
        onDismiss={hideJoinWAGroupCard}
        isCommunityMember={isCommunityMember}
      />
    </div>
  ) : null;
};

export default JoinWhatsappChatCard;
