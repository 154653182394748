export const getFirstImageFromContent = (content) => {
  if (!content) {
    return null;
  }
  const findImageSrc = (node) => {
    if (Array.isArray(node.children)) {
      for (let child of node.children) {
        if (child.type === 'image') {
          return child.src;
        }
        const nestedImageSrc = findImageSrc(child);
        if (nestedImageSrc) {
          return nestedImageSrc;
        }
      }
    }
    return null;
  };

  return findImageSrc(content.root);
};

export const getDescriptionFromContent = (content) => {
  const arrayOfText = [];
  const childrenTexts = content?.root?.children?.filter(
    (element) => element.type === 'paragraph'
  );
  if (childrenTexts) {
    childrenTexts.map((paragraphs) => {
      paragraphs?.children?.map((ele) => {
        arrayOfText.push(ele?.text);
      });
    });
  }

  const description = arrayOfText.join(' ');
  return description;
};
