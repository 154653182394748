import React from 'react';

function FeedSkeleton() {
  return (
    <div>
      <div className="mb-16 h-40 w-120 animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
      <div className="mb-16 h-225 w-full animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
      <div className="mb-16 h-225 w-full animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
      <div className="mb-16 h-225 w-full animate-pulse rounded-8 bg-npl-neutral-light-solid-4"></div>
    </div>
  );
}

export default FeedSkeleton;
