import React from 'react';

function EntityCardSkeletion() {
  return (
    <div className="c-UpcomingCard relative w-full rounded-16 bg-white-default shadow-npl-styles-button-shadow">
      <div
        className="aspect-h-1 aspect-w-2 animate-pulse
         bg-npl-neutral-light-solid-4"></div>

      <div className="flex flex-col gap-12 p-16 ">
        <div className="h-20 w-[200px] animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
        <div className="flex flex-col gap-8">
          <div className="flex h-16 gap-8">
            <div className="h-16 w-16 animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
            <div className="h-16 w-[100px] animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
          </div>
          <div className="flex h-16 gap-8">
            <div className="h-16 w-16 animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
            <div className="h-16 w-[100px] animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
          </div>
          <div className="flex h-16 gap-8">
            <div className="h-16 w-16 animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
            <div className="h-16 w-[100px] animate-pulse rounded-full bg-npl-neutral-light-solid-4"></div>
          </div>
        </div>
        <div className="flex h-16 w-[50px] animate-pulse gap-4 rounded-8 bg-npl-neutral-light-solid-4"></div>
      </div>
    </div>
  );
}

export default EntityCardSkeletion;
