import React from 'react';

export const DotButton = (props) => {
  const { children, isAutoplayStopped, ...restProps } = props;

  return (
    <button type="button" {...restProps}>
      {children}
      {/* For animation of dot buttons */}
      {!isAutoplayStopped && (
        <div className="loading absolute left-0 top-0 h-4 rounded-full bg-npl-neutral-light-solid-9"></div>
      )}
    </button>
  );
};
