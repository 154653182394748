import { MAX_LIMIT_MEMBERS_COUNT } from './constants';
import { SHARE_COMMUNITY_EVENT } from '@/utility/member/analytics_action_ids';
import { getCommunityDataForAnalytics } from '@/utility/analytics';
import { trackGeneralEvent } from '@/utility/member/analytics';
import { getTimeAgoString } from '@/utility/dateHelper';

const formatPostItemData = (post) => {
  if (post?.content) {
    return {
      ...post
    };
  }
  const author = post?.author;

  const authorCardProps = {
    avatarProps: {
      alt: 'user-img',
      mobileImgData: { src: author?.profileImage }
    },
    authorName: `${author?.firstName} ${author?.lastName}`,
    authorRole: author?.role,
    postDate: getTimeAgoString(post?.modifiedAt)
  };

  const formattedPostData = {
    authorCardProps,
    title: post?.title,
    description: post?.description,
    jsonContent: post?.jsonContent,
    info: post?.info,
    imgSrc: post?.imgSrc,
    videoSrc: post?.videoSrc
  };

  return formattedPostData;
};

export const formatPostsData = (posts = []) =>
  posts?.map(formatPostItemData);

export const filterOutUsersWithoutPersonalData = (item) =>
  item?.firstName && item?.lastName ? item : null;

export const getFormattedCommunityMembersData = (subscriptions) =>
  subscriptions
    ?.filter(filterOutUsersWithoutPersonalData)
    ?.map(({ firstName, lastName, profileImage }) => ({
      name: `${firstName} ${lastName}`,
      avatar: profileImage
    }))
    .slice(0, MAX_LIMIT_MEMBERS_COUNT);

export const trackShareCommunity = (community) => {
  const params = getCommunityDataForAnalytics(community);
  trackGeneralEvent(SHARE_COMMUNITY_EVENT, params);
};
