import React from 'react';

import { t } from '@/utility/localization';

import FeedSkeleton from '@/components/features/CommunityPage/components/skeletons/FeedSkeleton';
import NPLButton from '@/components/npl/NPLButton';

import FeedCard from './FeedCard';

const FeedsCard = ({
  communityData,
  posts = [],
  isCommunityAdmin = false,
  isCommunityMember = false,
  isPendingApproval,
  onJoinCommunityClick,
  showSeeMoreCta = false,
  onSeeMoreClick,
  loadingMorePosts = false
}) => {
  const { title, slug, _id: communityId, config } = communityData || {};
  const { hideLikesOnPost, hideViewsOnPost } = config || {};
  return (
    <div
      // data-aos="fade-up"
      // data-aos-delay="200"
      // data-aos-easing="ease"
      // data-aos-duration="500"
      // data-aos-once={true}
      className="flex w-full flex-col">
      {/* Posts List */}
      <div className="flex flex-col gap-32">
        {posts.map((post) => {
          const { _id: postId } = post;

          return (
            <div key={postId}>
              <FeedCard
                post={post}
                communityLink={slug}
                isCommunityMember={isCommunityMember}
                isCommunityAdmin={isCommunityAdmin}
                isPendingApproval={isPendingApproval}
                onJoinCommunityClick={onJoinCommunityClick}
                communityName={title}
                communityId={communityId}
                hideViewCount={hideViewsOnPost}
                hideLikeCount={hideLikesOnPost}
              />
            </div>
          );
        })}
      </div>
      {loadingMorePosts && <FeedSkeleton />}

      {/* See More Cta */}
      {showSeeMoreCta && !loadingMorePosts && (
        <div className="flex justify-center mt-32">
          <NPLButton
            onClick={onSeeMoreClick}
            hierarchy="outline"
            buttonText={t('see-more-posts')}
            size="lg"
            stretch
          />
        </div>
      )}
    </div>
  );
};

export default FeedsCard;
