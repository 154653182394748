import classNames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import React, { useMemo } from 'react';

import { getFirstImageFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import { trackCommLandingPageUnlockPostClick } from '@/features/Feed/analytics';
import BlurImageWithLock from '@/features/Feed/components/BlurImageWithLock';
import useFeedAnalytics from '@/features/Feed/hooks/useFeedAnalytics';
import FeedContent from '@/features/Feed/pages/PostPublicPage/FeedContent';
import PostActionBtnsRow from '@/features/Feed/pages/PostPublicPage/PostActionBtnsRow';
import PostAuthorData from '@/features/Feed/pages/PostPublicPage/PostAuthorData';
import PostContent from '@/features/Feed/pages/PostPublicPage/PostContent';
import {
  VISIBILITY_TYPE_PUBLIC,
  getPostShareUrlWithCommunityLink
} from '@/features/Feed/utils';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import Icon from '@/components/npl/Icon';
import NPLButton from '@/components/npl/NPLButton';

import usePostReaction from '@/hooks/usePostReaction';

import {
  VariableTypes,
  replaceVariables
} from '@/pages/portal/utils/common';

const FeedCard = ({
  post,
  communityLink,
  isCommunityMember,
  isCommunityAdmin,
  isPendingApproval,
  onJoinCommunityClick,
  communityName,
  communityId,
  hideViewCount,
  hideLikeCount
}) => {
  const {
    visibilityType,
    _id: postId,
    title,
    commentCount,
    content,
    blurImageUrl,
    isPinned,
    totalImpressions,
    author,
    isStaticPost
  } = post || {};
  const router = useRouter();
  const { isGtEqMd } = useWindowWidthContext();

  const feedPublicPageLink = isStaticPost
    ? ''
    : getPostShareUrlWithCommunityLink(post, communityLink, true);
  const canUserViewPost =
    isCommunityMember || visibilityType === VISIBILITY_TYPE_PUBLIC;

  const { feedCardRef } = useFeedAnalytics({ postId, canUserViewPost });

  const { isPostLiked, postLikeCount, handleReactionButtonClick } =
    usePostReaction({
      post,
      postType: 'post',
      communityId,
      postId
    });

  const editorVariables = [
    {
      text: communityName,
      variable: VariableTypes.CommunityName
    },
    {
      text: 'User',
      variable: VariableTypes.MemberName
    },
    {
      text: `${author?.firstName} ${author?.lastName}`,
      variable: VariableTypes.CommunityManagerName
    }
  ];

  const routeToFeedPublicPage = () => {
    if (feedPublicPageLink) router.push(feedPublicPageLink);
  };

  const handlePostInteractions = (funcToExecute) => {
    if (!isCommunityMember) {
      onJoinCommunityClick(post);
    } else {
      funcToExecute();
    }
  };

  const firstImageInContent = useMemo(
    () => getFirstImageFromContent(content),
    [content]
  );

  const renderPostEngagementRow = () => {
    return (
      <PostActionBtnsRow
        postCreationDateString={post?.createdAt}
        isPostLiked={isPostLiked}
        likeCount={postLikeCount}
        commentCount={commentCount}
        impressionCount={totalImpressions}
        onLikeClick={(e) => {
          e.stopPropagation();
          handlePostInteractions(handleReactionButtonClick);
        }}
        onCommentClick={(e) => {
          e.stopPropagation();
          handlePostInteractions(routeToFeedPublicPage);
        }}
        isCommunityAdmin={isCommunityAdmin}
        hideLikeCount={hideLikeCount}
        hideViewCount={hideViewCount}
      />
    );
  };

  const renderLockedView = () => {
    if (isStaticPost) {
      return (
        <>
          <div className="flex justify-between">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </div>
          <div className="mt-20">
            <div className="text-heading-sm font-semibold">{title}</div>
            <div className="mt-16">
              <BlurImageWithLock
                imageUrl={blurImageUrl}
                onClick={() => onJoinCommunityClick(post)}
                isPendingApproval={isPendingApproval}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="flex justify-between">
          <div rel="noreferrer">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </div>
          <div className="flex h-fit gap-4 rounded-[6px] border-1 border-npl-neutral-light-solid-6 p-4 shadow-npl-styles-button-shadow">
            <Icon name="lock-01" width={16} height={16} fill="#1B1B18A6" />
            <span className="text-label-sm font-medium text-npl-text-icon-on-light-surface-secondary">
              {t('locked')}
            </span>
          </div>
        </div>
        <div className="mt-20">
          <div className="text-heading-sm font-semibold break-words">
            {replaceVariables(title, editorVariables)}
          </div>
          <div className="mt-16">
            <FeedContent
              isStaticPost={isStaticPost}
              content={content}
              feedCoverImageUrl={blurImageUrl}
              isUnlockedView={canUserViewPost}
              onLockButtonClick={() => onJoinCommunityClick(post)}
              isPendingApproval={isPendingApproval}
            />
          </div>
          <div className="mt-20 flex">{renderPostEngagementRow()}</div>
          <div className="mt-8">
            {!blurImageUrl && (
              <NPLButton
                hierarchy="neutral_primary"
                leadIcon="lock-01"
                disabled={isPendingApproval}
                buttonText={
                  isPendingApproval
                    ? t('pending-approval')
                    : t('join-to-unlock')
                }
                stretch={!isGtEqMd}
                onClick={(e) => {
                  e.stopPropagation();
                  onJoinCommunityClick(post);
                  trackCommLandingPageUnlockPostClick({
                    communityId: post?.communities?.[0],
                    postId
                  });
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  const renderUnlockedView = () => {
    if (isStaticPost) {
      return (
        <>
          <div className="flex justify-between">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </div>
          <div className="mt-20">
            <div className="text-heading-sm font-semibold">{title}</div>
            <div className="mt-16">
              <PostContent
                postId={postId}
                content={content}
                // community={community}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="flex justify-between">
          <Link href={feedPublicPageLink} rel="noreferrer">
            <PostAuthorData post={post} showPostVisibilityType={false} />
          </Link>
        </div>
        <div className="mt-20">
          <div className="text-heading-sm font-semibold">
            {replaceVariables(title, editorVariables)}
          </div>
          <div className="mt-16">
            <FeedContent
              content={content}
              isUnlockedView={true}
              feedCoverImageUrl={firstImageInContent}
              editorVariables={editorVariables}
            />
          </div>
          <div className="mt-20 flex items-center gap-2">
            {renderPostEngagementRow()}
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      ref={feedCardRef}
      onClick={routeToFeedPublicPage}
      className={classNames(
        'c-FeedCard group rounded-16 border-1 border-npl-neutral-light-solid-4 bg-white-default p-24 shadow-npl-styles-button-shadow transition-all',
        {
          'hover:cursor-pointer hover:border-npl-neutral-light-solid-4 hover:bg-npl-neutral-light-solid-2':
            !isStaticPost
        }
      )}>
      {isPinned && (
        <div className="mb-4">
          <Icon
            name="pin-filled"
            width={16}
            height={16}
            fill="#1B1B1880"
          />
        </div>
      )}
      {canUserViewPost ? renderUnlockedView() : renderLockedView()}
    </div>
  );
};

export default FeedCard;
