import {
  getPagedAnnouncements,
  getPinnedAnnouncements
} from '@/services/community/communitiesAnnouncementsService';
import { useCallback, useEffect, useMemo, useState } from 'react';

const DEFAULT_PAGE_NUMBER = 1;
const usePosts = ({ communityId, pageSize = 10 }) => {
  // loading and error state
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [pinnedPost, setPinnedPost] = useState(null);
  const [posts, setPosts] = useState([]);
  const [paginationMetadata, setPaginationMetadata] = useState(null);
  const [pageNum, setPageNum] = useState(DEFAULT_PAGE_NUMBER);
  const [loadingMorePosts, setLoadingMorePosts] = useState(false);

  const allPosts = useMemo(() => {
    return [...(pinnedPost ? [pinnedPost] : []), ...posts];
  }, [pinnedPost, posts]);

  const getNextPage = useCallback(() => {
    if (paginationMetadata?.next) {
      setPageNum((prev) => prev + 1);
    }
  }, [paginationMetadata?.next]);

  const fetchPinnedPost = useCallback(async () => {
    try {
      const { data, error } = await getPinnedAnnouncements(communityId);
      if (error) {
        console.error(error);
        return;
      }
      const pinnedPost = data?.[0];
      setPinnedPost(pinnedPost);
    } catch (error) {
      console.error('Error fetching pinned posts ', error);
    }
  }, [communityId]);

  const fetchPosts = useCallback(async () => {
    if (!pageNum) return;
    try {
      if (pageNum === 1) {
        // only set isLoading only initial Page
        setLoading(true);
      } else {
        setLoadingMorePosts(true);
      }
      setError('');
      const { data, error } = await getPagedAnnouncements(communityId, {
        paginate: 1,
        pageNum: pageNum,
        pageSize: pageSize
      });

      if (error) {
        console.error(error);
        setError(error);
        return;
      }

      if (pageNum === DEFAULT_PAGE_NUMBER) {
        setPosts(data?.data);
      } else {
        setPosts((prev) => {
          return [...prev, ...(data?.data || [])];
        });
      }
      setPaginationMetadata(data?.metadata);
    } catch (error) {
      console.error(error);
      setError(error);
    } finally {
      setLoading(false);
      setLoadingMorePosts(false);
    }
  }, [communityId, pageNum, pageSize]);

  useEffect(() => {
    fetchPinnedPost();
  }, [fetchPinnedPost]);

  useEffect(() => {
    fetchPosts();
  }, [fetchPosts]);

  return {
    loading,
    error,
    posts,
    allPosts,
    paginationMetadata,
    getNextPage,
    loadingMorePosts
  };
};

export default usePosts;
