import config from '@/utility/config';
import publicAxiosRequest from './helpers/publicAxiosRequest';
import { getAxiosErrorMsg } from './helpers';

interface PayloadTypes {
  entitytype: string;
  sessionid: string;
  entityid: string;
  m_time: Date;
  user_id: string;
  env: string;
}

const { analyticsApiBasePath } = config;

export const trackFeedCardView = async (payload: PayloadTypes) => {
  const apiPath = `${analyticsApiBasePath}/data`;
  const { data } = await publicAxiosRequest.post(apiPath, payload);

  const error = data?.error;

  if (error) {
    console.error(getAxiosErrorMsg(error));
    return { error };
  }

  return { data, error: null };
};
