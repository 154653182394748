import React, { useMemo } from 'react';
import { isValid, parse } from 'date-fns';
import classNames from 'classnames';
import { formatDateTimeWithLocale } from '@/utility/localization';

const CalendarDayBox = (props) => {
  const { date, dark, accent, size = 'md', bordered = true } = props;

  const boxSizeClassName = useMemo(() => {
    switch (size) {
      case 'lg':
        return 'w-64 h-64';
      case 'md':
      default:
        return 'w-40 h-40';
    }
  }, [size]);

  const { month, day } = useMemo(() => {
    let startDate;

    const isDateObj = date instanceof Date;
    const isDateInStr = typeof date === 'string';

    if (isDateObj || (isDateInStr && date?.includes('T'))) {
      startDate = new Date(date);
    } else {
      // only needed if date is a string in format yyyy-MM-dd
      startDate = parse(
        isValid(date) ? date : new Date().toISOString(),
        'yyyy-MM-dd',
        new Date()
      );
    }

    // Check if 'date' is a valid date
    if (!isValid(startDate)) {
      return {
        day: formatDateTimeWithLocale(new Date(), 'dd'),
        month: formatDateTimeWithLocale(
          new Date(),
          'MMM'
        ).toLocaleUpperCase()
      };
    }

    return {
      day: formatDateTimeWithLocale(startDate, 'dd'),
      month: formatDateTimeWithLocale(startDate, 'MMM').toLocaleUpperCase()
    };
  }, [date]);

  return (
    <div
      className={classNames(
        'flex-shrink-0 overflow-hidden rounded-8 border-npl-neutral-light-solid-6 text-center flex flex-col',
        {
          'border-1': bordered,
          'shadow-sm': !bordered
        },
        boxSizeClassName
      )}>
      <div
        className={classNames('flex-shrink-0', {
          'bg-npl-neutral-light-solid-6': !dark && !accent,
          'bg-npl-yellow-dark-solid-6': dark,
          'bg-npl-yellow-light-alpha-6': accent
        })}>
        <p
          className={classNames('font-medium', {
            'text-label-xs': size === 'md',
            'text-label-md py-4': size === 'lg',
            'text-dark-1b text-opacity-65': !dark && !accent,
            'text-npl-text-icon-on-dark-primary': dark,
            'text-npl-text-secondary-on-light': accent
          })}>
          {month}
        </p>
      </div>
      <div className="flex-grow flex items-center justify-center">
        <p
          className={classNames('font-medium text-dark-1b', {
            'text-label-md': size === 'md',
            'text-heading-xs': size === 'lg'
          })}>
          {day}
        </p>
      </div>
    </div>
  );
};

export default CalendarDayBox;
