import React, { useEffect, useMemo, useRef, useState } from 'react';

import { getDescriptionFromContent } from '@/utility/lexicalContentHelper';
import { t } from '@/utility/localization';

import { useWindowWidthContext } from '@/contexts/WindowWidthContext';

import NextImage from '@/components/common/NextImage';

import { replaceVariables } from '@/pages/portal/utils/common';

import BlurImageWithLock from '../../components/BlurImageWithLock';
import { useImageLoader } from '../../components/useImageLoader';

type Props = {
  content: object;
  editorVariables: object;
  feedCoverImageUrl: string;
  isPendingApproval: boolean;
  isStaticPost: boolean;
  isUnlockedView: boolean;
  onLockButtonClick: () => void;
};

export default function FeedContent({
  content,
  isUnlockedView,
  feedCoverImageUrl,
  onLockButtonClick,
  editorVariables,
  isPendingApproval
}: Props) {
  const { isGtEqMd } = useWindowWidthContext();
  const { dimensions, isLoading } = useImageLoader(feedCoverImageUrl);
  const paragraphRef = useRef<HTMLDivElement>(null);
  const [isTextTruncated, setIsTextTruncated] = useState(false);

  const firstParagraphInContent = useMemo(
    () => getDescriptionFromContent(content),
    [content]
  );

  useEffect(() => {
    const checkTextTruncation = () => {
      if (paragraphRef.current) {
        const { scrollHeight, clientHeight } = paragraphRef.current;
        setIsTextTruncated(scrollHeight > clientHeight);
      }
    };

    checkTextTruncation();
    window.addEventListener('resize', checkTextTruncation);

    return () => {
      window.removeEventListener('resize', checkTextTruncation);
    };
  }, [firstParagraphInContent, isGtEqMd]);

  const renderImage = () => {
    if (isLoading) {
      return (
        <div
          className={`animate-pulse rounded-12 bg-npl-neutral-light-solid-4`}
        />
      );
    }

    if (isUnlockedView) {
      return (
        <NextImage
          className="rounded-12"
          mobileImgProps={{
            src: feedCoverImageUrl,
            width: dimensions.width,
            height: dimensions.height,
            objectFit: 'cover',
            quality: 50
          }}
        />
      );
    }

    return (
      <BlurImageWithLock
        imageUrl={feedCoverImageUrl}
        onClick={onLockButtonClick}
        isPendingApproval={isPendingApproval}
      />
    );
  };

  return (
    <div>
      {(feedCoverImageUrl || firstParagraphInContent) && (
        <div>
          {firstParagraphInContent && (
            <div className="mb-16">
              <div
                ref={paragraphRef}
                className={`${
                  isGtEqMd ? 'line-clamp-2' : 'line-clamp-4'
                } relative text-para-sm text-npl-text-icon-on-light-surface-primary`}>
                {firstParagraphInContent &&
                  replaceVariables(
                    firstParagraphInContent,
                    editorVariables
                  )}
                {isTextTruncated && (
                  <div
                    onClick={onLockButtonClick}
                    className="absolute bottom-0 right-0 z-[1] cursor-pointer bg-white-default px-4 font-semibold text-npl-text-icon-on-light-surface-tertiary group-hover:bg-npl-neutral-light-solid-2">
                    ...{t('see-more')}
                  </div>
                )}
              </div>
            </div>
          )}

          {feedCoverImageUrl && renderImage()}
        </div>
      )}
    </div>
  );
}
