import config from '@/utility/config';
import { t } from '@/utility/localization';

export const MAX_LIMIT_MEMBERS_COUNT = 5;

export const getStaticPost = (activeCommunity) => {
  const blurredWelcomeBanner = `${config.imagesBasePath}/nasIO/homePage/png/welcome-banner-blurred.png`;

  const welcomeBannerImageUrl = `${config.imagesBasePath}/nasIO/homePage/png/welcome-banner-new.webp`;

  return {
    isStaticPost: true,
    isAnnouncement: true,
    blurImageUrl: blurredWelcomeBanner,
    author: {
      isActive: true,
      firstName: activeCommunity?.host?.name,
      lastName: '',
      role: t('commnity-admin'),
      profileImage:
        activeCommunity?.profileImage ??
        activeCommunity?.thumbnailImgData?.mobileImgData?.src
    },
    title: t('you-re-now-a-member-of-community', {
      communityName: activeCommunity?.title
    }),
    lastModifiedTimeStamp: activeCommunity?.createdAt,
    createdAt: activeCommunity?.createdAt,
    content: {
      root: {
        children: [
          {
            children: [
              {
                altText: 'Nas IO.jpeg',
                caption: {
                  editorState: {
                    root: {
                      children: [],
                      direction: null,
                      format: '',
                      indent: 0,
                      type: 'root',
                      version: 1
                    }
                  }
                },
                height: 0,
                maxWidth: '100%',
                showCaption: false,
                src: welcomeBannerImageUrl,
                type: 'image',
                version: 1,
                width: 0
              }
            ],
            direction: null,
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t('welcome-to-community-title', {
                  communityTitle: activeCommunity?.title
                }),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t(
                  'this-portal-is-a-private-space-for-community-members-its-where-you-can-read-important-announcements-view-upcoming-events-see-the-list-of-members-and-access-exclusive-content-in-the-resource-library'
                ),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t(
                  'for-now-you-just-need-to-do-one-thing-set-up-your-profile-in-the-portal'
                ),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t('heres-how-to-do-that'),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: 'normal',
                    style: '',
                    text: t(
                      'hover-over-the-avatar-in-the-top-right-corner-a-dropdown-menu-will-appear'
                    ),
                    type: 'text',
                    version: 1
                  }
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'listitem',
                version: 1,
                value: 1
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: 'normal',
                    style: '',
                    text: t(
                      'select-settings-your-account-information-will-appear'
                    ),
                    type: 'text',
                    version: 1
                  }
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'listitem',
                version: 1,
                value: 2
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: 'normal',
                    style: '',
                    text: t(
                      'select-the-camera-icon-on-the-avatar-to-change-your-profile-picture'
                    ),
                    type: 'text',
                    version: 1
                  }
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'listitem',
                version: 1,
                value: 3
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: 'normal',
                    style: '',
                    text: t(
                      'select-edit-profile-to-change-your-name-or-bio'
                    ),
                    type: 'text',
                    version: 1
                  }
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'listitem',
                version: 1,
                value: 4
              },
              {
                children: [
                  {
                    detail: 0,
                    format: 0,
                    mode: 'normal',
                    style: '',
                    text: t(
                      'select-update-links-to-include-your-social-media-handles'
                    ),
                    type: 'text',
                    version: 1
                  }
                ],
                direction: 'ltr',
                format: '',
                indent: 0,
                type: 'listitem',
                version: 1,
                value: 5
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'list',
            version: 1,
            listType: 'bullet',
            start: 1,
            tag: 'ul'
          },
          {
            children: [],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t('and-youre-done'),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          },
          {
            children: [
              {
                detail: 0,
                format: 0,
                mode: 'normal',
                style: '',
                text: t(
                  'well-be-back-with-more-information-soon-p-s-know-somebody-who-would-like-to-be-part-of-this-community-invite-your-friends'
                ),
                type: 'text',
                version: 1
              }
            ],
            direction: 'ltr',
            format: '',
            indent: 0,
            type: 'paragraph',
            version: 1
          }
        ],
        direction: 'ltr',
        format: '',
        indent: 0,
        type: 'root',
        version: 1
      }
    }
  };
};
