// communities.config.hideEventAttendeeCount = True
// Show only if:
//      commuity_event.config.hideEventAttendeeCount = false
// Do not show if:
//      commuity_event.config.hideEventAttendeeCount  = true/null/undefined

// communities.config.hideEventAttendeeCount = False
// Show only if:
//      commuity_event.config.hideEventAttendeeCount = false/null/undefined
// Do not show if:
//      commuity_event.config.hideEventAttendeeCount  = true

export const checkHideEventAttendees = (
  communityHideAttendee,
  eventHideAttendee
) => {
  if (communityHideAttendee) {
    return eventHideAttendee !== false;
  } else {
    return eventHideAttendee;
  }
};
