import dynamic from 'next/dynamic';

const LottiePlayer = dynamic(
  () =>
    import('@lottiefiles/react-lottie-player').then(
      (module) => module.Player
    ),
  {
    ssr: false
  }
);

const Player = ({ autoplay, loop, src, className }) => {
  return (
    <LottiePlayer
      autoplay={autoplay}
      loop={loop}
      src={src}
      className={className}
    />
  );
};

export default Player;
