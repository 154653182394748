import { useCallback, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import NPLButton from '@/components/npl/NPLButton';
import { DotButton } from './components/DotButtons';
import useDotButton from './hooks/useDotButton';
import classNames from 'classnames';

const CustomCarousel = ({
  title,
  children,
  autoplay,
  customOptions,
  customAutoplayOptions,
  showArrowButtons = true,
  showDotButtons = true,
  showTopRightArrowButtons = false,
  customParentClassname
}) => {
  const [isAutoplayStopped, setIsAutoplayStopped] = useState(false);
  const options = {
    containScroll: false,
    loop: true,
    ...customOptions
  };

  const autoplayOptions = {
    delay: 8000,
    stopOnInteraction: true,
    ...customAutoplayOptions // override initial settings if any
  };

  const [emblaRef, emblaApi] = useEmblaCarousel(
    options,
    autoplay ? [Autoplay(autoplayOptions)] : []
  );

  // stop autoplay on any buttonClick
  const onButtonClick = useCallback((emblaApi) => {
    const { autoplay } = emblaApi.plugins();
    if (!autoplay) return;
    if (autoplay.options.stopOnInteraction !== false) autoplay.stop();
    setIsAutoplayStopped(true);
  }, []);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(
    emblaApi,
    onButtonClick,
    isAutoplayStopped
  );

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
    if (onButtonClick) onButtonClick(emblaApi);
  }, [emblaApi, onButtonClick]);

  return (
    <>
      <div className="embla">
        {(title || showTopRightArrowButtons) && (
          <div className="mb-16 flex items-center justify-between">
            <div className="text-heading-lg font-semibold">{title}</div>
            {showTopRightArrowButtons && (
              <div className="flex gap-8">
                <NPLButton
                  leadIcon="chevron-left"
                  hierarchy="outline"
                  size="sm"
                  rounded
                  multiply
                  onClick={onPrevButtonClick}
                />
                <NPLButton
                  leadIcon="chevron-right"
                  hierarchy="outline"
                  rounded
                  multiply
                  size="sm"
                  onClick={onNextButtonClick}
                />
              </div>
            )}
          </div>
        )}
        <div
          className={`overflow-hidden ${customParentClassname}`}
          ref={emblaRef}>
          <div className=" flex">{children}</div>
        </div>

        <div className="flex items-center justify-center">
          {showArrowButtons && (
            <NPLButton
              leadIcon="chevron-left"
              hierarchy="plain"
              size="sm"
              rounded
              onClick={onPrevButtonClick}
            />
          )}
          {showDotButtons && (
            <div className="z-1 flex items-center justify-center ">
              {scrollSnaps.map((_, index) => (
                <div
                  key={index}
                  className="cursor-pointer p-8"
                  onClick={() => onDotButtonClick(index)}>
                  <DotButton
                    className={classNames(
                      'flex h-4 w-4 items-center rounded-8 bg-npl-neutral-light-solid-6 transition-all duration-500',
                      index === selectedIndex
                        ? 'embla__dot--selected relative w-[32px] overflow-hidden bg-npl-neutral-light-solid-4'
                        : '',
                      index === selectedIndex &&
                        (isAutoplayStopped || !autoplay)
                        ? 'bg-npl-neutral-light-solid-9'
                        : ''
                    )}
                    isAutoplayStopped={isAutoplayStopped}
                  />
                </div>
              ))}
            </div>
          )}
          {showArrowButtons && (
            <NPLButton
              leadIcon="chevron-right"
              hierarchy="plain"
              rounded
              size="sm"
              onClick={onNextButtonClick}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default CustomCarousel;
