export const HOME_MP_RSVP_EVENT = 'home_mp_rsvp_event';
export const HOME_MP_GET_TICKET_EVENT = 'home_mp_get_ticket_event';
export const HOME_MP_EVENT_CARD_CLICK = 'home_mp_event_card_click';

export const EVENTS_MP_VIEW_EVENT_PAGE = 'events_mp_view_event_page';
export const EVENTS_MP_VIEW_EVENT = 'events_mp_view_event';

export const EVENTS_MP_CARD_WATCH_RECORDING_CLICK =
  'events_mp_card_watch_recording_click';
export const EVENTS_MP_CARD_JOIN_NOW_CLICK =
  'events_mp_card_join_now_click';
export const EVENTS_MP_CARD_RSVP_EVENT = 'events_mp_card_rsvp_event';
export const EVENTS_MP_CARD_GET_TICKET_CLICK =
  'events_mp_card_get_ticket_click';
export const EVENTS_MP_CARD_CANCEL_ATTENDANCE_CLICK =
  'events_mp_card_cancel_attendance_click';

// export const EVENTS_MP_PAGE_RSVP_EVENT = 'events_mp_page_rsvp_event';
export const EVENTS_MP_POPUP_WATCH_RECORDING_CLICK =
  'events_mp_popup_watch_recording_click';
export const EVENTS_MP_POPUP_JOIN_NOW_CLICK =
  'events_mp_popup_join_now_click';
export const EVENTS_MP_POPUP_RSVP_EVENT = 'events_mp_popup_rsvp_event';
export const EVENTS_MP_POPUP_GET_TICKET_CLICK =
  'events_mp_popup_get_ticket_click';

export const LIBRARY_MP_VIEW_COURSE = 'library_mp_view_course';
export const LIBRARY_MP_VIEW_COURSE_MODULE =
  'library_mp_view_course_module';
export const LIBRARY_MP_VIEW_COLLECTION = 'library_mp_view_collection';
export const LIBRARY_MP_VIEW_COLLECTION_ITEM =
  'library_mp_view_collection_item';
export const LIBRARY_MP_VIEW_LOCKED_FOLDER = 'library_folder_view_locked';
export const LIBRARY_MP_CLICK_ON_BUY = 'library_folder_purchase_start';
export const LIBRARY_MP_PURCHASE_SUCCESS =
  'library_folder_purchase_success';
export const LIBRARY_MP_VIEW_FOLDER = 'library_folder_view';
export const LIBRARY_MP_VIEW_ITEM = 'library_folder_view_item';
export const LIBRARY_MP_DOWNLOAD = 'library_folder_download_file';

export const SHARE_COMMUNITY_EVENT = 'mp_share_community';
export const COMMUNITY_CHAT_CLICK_EVENT = 'mp_community_chat';

// START - home page post comments analytic
export const HOME_MP_COMMENT_CLICK = 'home_mp_comment_click';
export const HOME_MP_REPLY_CLICK = 'home_mp_reply_click';

export const HOME_MP_ADD_COMMENT_EVENT = 'home_mp_add_comment_event';
export const HOME_MP_ADD_REPLY_EVENT = 'home_mp_add_reply_event';

export const HOME_MP_SHOW_MORE_COMMENT_EVENT =
  'home_mp_show_more_comment_event';
export const HOME_MP_SHOW_MORE_REPLY_EVENT =
  'home_mp_show_more_reply_event';

export const HOME_MP_LIKE_ANNOUNCEMENT = 'home_mp_like_announcement';
export const HOME_MP_UNLIKE_ANNOUNCEMENT = 'home_mp_unlike_announcement';

export const HOME_MP_LIKE_COMMENT = 'home_mp_like_comment';
export const HOME_MP_UNLIKE_COMMENT = 'home_mp_unlike_comment';

export const HOME_MP_LIKE_REPLY = 'home_mp_like_reply';
export const HOME_MP_UNLIKE_REPLY = 'home_mp_unlike_reply';
// END - home page post comments analytic

export const SHARE_POST_CLICKED = 'share_post_clicked';
