import NplToolTip from '@/components/common/NplToolTip';
import IconWithTextSection from '@/features/community/components/FeedPosts/IconWithTextSection';
import classNames from 'classnames';
import React from 'react';
import { useWindowWidthContext } from '@/contexts/WindowWidthContext';
import { t } from '@/utility/localization';
import { IconName } from '@/components/npl/Icon';

type Props = {
  icon: IconName<'common'>;
  onClick: (e: React.MouseEvent) => void;
  title: string | number;
  iconColor: string;
  stretch?: boolean;
  tooltipText?: string;
};

const PostActionButton: React.FC<Props> = ({
  icon,
  iconColor = '#1B1B18A6',
  onClick,
  title,
  stretch = false,
  tooltipText
}) => {
  const { isGtEqMd } = useWindowWidthContext();

  const getTooltipText = () => {
    switch (icon) {
      case 'eye':
        return t('views');
      case 'heart-filled':
      case 'heart':
        return t('like');
      case 'message-text-square-02':
        return t('comment');
      default:
        return '';
    }
  };

  const content = isGtEqMd ? (
    <NplToolTip
      text={tooltipText ? tooltipText : getTooltipText()}
      wrapChildrenInButton={false}>
      <div
        onClick={onClick}
        className={classNames(
          'rounded-100 p-8 text-center mix-blend-multiply',
          {
            'cursor-pointer hover:bg-npl-neutral-light-solid-2': onClick
          }
        )}>
        <IconWithTextSection
          icon={icon}
          iconWidthHeight={20}
          title={title}
          iconColor={iconColor}
        />
      </div>
    </NplToolTip>
  ) : (
    <div
      onClick={onClick}
      className={classNames(
        'flex items-center justify-center rounded-100 p-8 mix-blend-multiply',
        { 'cursor-pointer hover:bg-npl-neutral-light-solid-2': onClick }
      )}>
      <IconWithTextSection
        icon={icon}
        iconWidthHeight={20}
        title={title}
        iconColor={iconColor}
      />
    </div>
  );

  return stretch ? (
    <div className="flex-grow justify-center">{content}</div>
  ) : (
    content
  );
};

export default PostActionButton;
