// useImageLoader.ts
import { useState, useEffect } from 'react';

interface ImageDimensions {
  width: number;
  height: number;
}

export const useImageLoader = (
  imageUrl: string,
  maxWidth: number = 590,
  maxHeight: number = 421
) => {
  const [dimensions, setDimensions] = useState<ImageDimensions>({
    width: maxWidth,
    height: maxHeight
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (!imageUrl) {
      setIsLoading(false);
      return;
    }

    const img = new Image();
    img.onload = () => {
      const aspectRatio = img.width / img.height;
      let newWidth = img.width;
      let newHeight = img.height;

      // Adjust dimensions to fit within maxWidth and maxHeight
      if (newWidth > maxWidth) {
        newWidth = maxWidth;
        newHeight = newWidth / aspectRatio;
      }

      if (newHeight > maxHeight) {
        newHeight = maxHeight;
        newWidth = newHeight * aspectRatio;
      }

      setDimensions({
        width: Math.round(newWidth),
        height: Math.round(newHeight)
      });
      setIsLoading(false);
    };
    img.onerror = () => {
      setIsLoading(false);
    };
    img.src = imageUrl;
  }, [imageUrl, maxWidth, maxHeight]);

  return { dimensions, isLoading };
};
